<template>
  <ion-page>
    <Header back-to="/" />
    <ion-content :fullscreen="true">
      <div class="container">
        <div class="flex col-center">
          <h2 class="title">Verifikasi Email</h2>
          <div class="text-center text-sm">{{ text }}</div>
        </div>
        <div class="flex col-center mt-2" v-if="resend">
          <a href="/send-link-verification" class="link">Kirim ulang kode verifikasi.</a>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header
  },
  data () {
    return {
      text: 'Email anda berhasil di verifikasi.',
      email: null,
      code: null,
      resend: false
    }
  },
  mounted () {
    this.verify()
  },
  methods: {
    async verify () {
      let email = this.$route.query.email
      let code = this.$route.query.code

      if(email == '' || code == '') {
        this.text = 'Link verifikasi kedaluwarsa.'
      }

      let resp = await this.$api.post('/api/email/verify', {
        email: email,
        code: code
      })

      if(! resp.error) {
        this.$store.commit('auth/setToken', resp.data);
        this.$store.dispatch('auth/fetchUser');
      }

      if(resp.error) {
        this.resend = true;
        this.text = 'Link verifikasi kedaluwarsa.'
      }
    }
  }
 });
</script>

<style>

</style>
